<script setup>
import { useKmobAkkDetail } from '~/stores/kmob/akk/detail'
import { useKmobAkkJob } from '~/stores/kmob/akk/job'

const store = useKmobAkkJob()
const detailStore = useKmobAkkDetail()
const { application } = storeToRefs(detailStore)
await store.getAkkJobUsahaAction(application.value.prospect_id, 'USAHA')
</script>

<template>
  <div>
    <KmobCard title="Data Usaha" :border-bottom="false" class="mx-8">
      <KmobRenderDynamicContent :data="store.renderUsaha" />
    </KmobCard>

    <KmobFoto title="Dokumen Lingkungan Usaha" :photo="store.photoUsahaUrls" />
  </div>
</template>
