import type { BusinessInfo, Job, JobTitle, JobWiraswasta, Jobs, informasiPihakKetiga } from '~/type/kmob/job-akk'

export const useKmobAkkJob = defineStore('akk-job', {
  state: (): Jobs => ({
    job: {} as Job,
    job_title: {} as JobTitle,
    job_wiraswasta: {} as JobWiraswasta,
    usaha: {} as BusinessInfo,
    informasi_pihak_ketiga: {} as informasiPihakKetiga,
    errors: false,
  }),
  getters: {
    photoUsahaUrls: (state) => {
      if (!state.usaha)
        return []
      return [
        { type: 'photo_barang_url', url: state.usaha.photo_barang_url },
        { type: 'photo_nota_url', url: state.usaha.photo_nota_url },
        { type: 'photo_selfie_cmo_url', url: state.usaha.photo_selfie_cmo_url },
        { type: 'photo_tampak_dalam_url', url: state.usaha.photo_tampak_dalam_url },
        { type: 'photo_tampak_depan_url', url: state.usaha.photo_tampak_depan_url },
        { type: 'photo_toko_online_url', url: state.usaha.photo_toko_online_url },
      ]
    },
    renderJobKaryawan: (state) => {
      if (!state.job)
        return []
      return [
        { title: 'Bidang Usaha Perusahaan Sesuai Aplikasi', contentItems: [{ content: state.job.is_industry_type_apk }], isShow: true },
        { title: 'Bidang Usaha Perusahaan', contentItems: [{ content: state.job.industry_type_name }], isShow: state.job.is_industry_type_apk === false },
        { title: 'Nama Perusahaan Sesuai Aplikasi', contentItems: [{ content: state.job.is_company_name_apk }], isShow: true },
        { title: 'Nama Perusahaan', contentItems: [{ content: state.job.company_name }], isShow: state.job.is_company_name_apk === false },
        { title: 'Jumlah Karyawan', contentItems: [{ content: state.job.num_of_employees }], isShow: true },
        { title: 'Verifikasi Dengan', contentItems: [{ content: state.job.verify_with }], isShow: true },
        { title: 'Bagian Pemberi Informasi', contentItems: [{ content: state.job.info_section }], isShow: true },
        { title: 'Verifikasi Melalui', contentItems: [{ content: state.job.verify_via }], isShow: true },
        { title: 'Nomor Handphone', contentItems: [{ content: state.job.verify_phone_number }], isShow: state.job.verify_via === 'Telepon' },
        { title: 'Catatan', contentItems: [{ content: state.job.notes }], isShow: true },

      ]
    },
    renderJobWiraswasta: (state) => {
      const isBussinessAdressApk = state.job_wiraswasta.is_business_address_apk === false
      if (!state.job_wiraswasta)
        return []
      return [
        { title: 'Bidang Usaha Perusahaan Sesuai Aplikasi', contentItems: [{ content: state.job_wiraswasta.is_industry_type_apk }], isShow: true },
        { title: 'Bidang Usaha Perusahaan ', contentItems: [{ content: state.job_wiraswasta.industry_type_name }], isShow: state.job_wiraswasta.is_industry_type_apk === false },
        { title: 'Nama Usaha Sesuai Aplikasi', contentItems: [{ content: state.job_wiraswasta.is_company_name_apk }], isShow: true, type: 'text' },
        { title: 'Nama Usaha ', contentItems: [{ content: state.job_wiraswasta.company_name }], isShow: state.job_wiraswasta.is_company_name_apk === false },
        { title: 'Alamat Usaha Sesuai Aplikasi', contentItems: [{ content: state.job_wiraswasta.is_business_address_apk }], isShow: true },
        { title: 'Alamat', contentItems: [{ content: state.job_wiraswasta.company_address }], isShow: isBussinessAdressApk },
        { title: 'RT', contentItems: [{ content: state.job_wiraswasta.company_rt }], isShow: isBussinessAdressApk },
        { title: 'RW', contentItems: [{ content: state.job_wiraswasta.company_rw }], isShow: isBussinessAdressApk },
        { title: 'Provinsi', contentItems: [{ content: state.job_wiraswasta.company_province }], isShow: isBussinessAdressApk },
        { title: 'Kabupaten/Kota', contentItems: [{ content: state.job_wiraswasta.company_city }], isShow: isBussinessAdressApk },
        { title: 'Kecamatan', contentItems: [{ content: state.job_wiraswasta.company_kecamatan }], isShow: isBussinessAdressApk },
        { title: 'Kelurahan', contentItems: [{ content: state.job_wiraswasta.company_kelurahan }], isShow: isBussinessAdressApk },
        { title: 'Kode Pos', contentItems: [{ content: state.job_wiraswasta.company_zip_code }], isShow: isBussinessAdressApk },
        { title: 'Verifikasi Dengan', contentItems: [{ content: state.job_wiraswasta.verify_with }], isShow: true },
        { title: 'Bagian Pemberi Informasi', contentItems: [{ content: state.job_wiraswasta.info_section }], isShow: true },
        { title: 'Catatan', contentItems: [{ content: state.job_wiraswasta.business_notes }], isShow: true },
      ]
    },

    renderJobPosition: (state) => {
      if (!state.job_title)
        return []
      return [
        { title: 'Jenis Pekerjaan Sesuai Aplikasi', contentItems: [{ content: state.job_title.is_job_type_apk }], isShow: true },
        { title: 'Jenis Pekerjaan', contentItems: [{ content: state.job_title.job_type_name }], isShow: state.job_title.is_job_type_apk === false },
        { title: 'Jabatan Sesuai Aplikasi', contentItems: [{ content: state.job_title.is_job_position_apk }], isShow: true },
        { title: 'Jabatan', contentItems: [{ content: state.job_title.job_position_name }], isShow: state.job_title.is_job_position_apk === false },
        { title: 'Status Karyawan Sesuai Aplikasi', contentItems: [{ content: state.job_title.is_employee_status_apk }], isShow: true },
        { title: 'Status Karyawan', contentItems: [{ content: state.job_title.employee_status_id }], isShow: state.job_title.is_employee_status_apk === false },
        { title: 'Mulai Bekerja Sesuai Aplikasi', contentItems: [{ content: state.job_title.is_work_since_apk }], isShow: true },
        { title: 'Bekerja Sejak', contentItems: [{ content: state.job_title.employment_since_month, type: 'month' }, { content: state.job_title.employment_since_year }], isShow: state.job_title.is_work_since_apk === false },

      ]
    },
    renderUsaha: (state) => {
      const checkExist = (isExist: any) => {
        return isExist === null || isExist === undefined ? '-' : isExist === true ? 'Ada' : 'Tidak Ada'
      }
      if (!state.usaha)
        return []
      return [
        { title: 'Mulai Usaha Sesuai Aplikasi', contentItems: [{ content: state.usaha.is_business_since_apk }], isShow: true },
        { title: 'Usaha Sejak', contentItems: [{ content: state.usaha.employment_since_month, type: 'month' }, { content: state.usaha.employment_since_year }], isShow: state.usaha.is_business_since_apk === false },
        { title: 'Tempat Usaha', contentItems: [{ content: state.usaha.business_place }], isShow: true },
        { title: 'Lokasi Usaha', contentItems: [{ content: state.usaha.business_location }], isShow: true },
        { title: 'Bukti Kepemilikan', contentItems: [{ content: checkExist(state.usaha.is_proof_ownership) }], isShow: true },
        { title: 'Status Tempat Usaha', contentItems: [{ content: state.usaha.business_place_status }], isShow: true },
        { title: 'Usaha Sejenis Terdekat', contentItems: [{ content: checkExist(state.usaha.is_business_nearby_exist) }], isShow: true },
        { title: 'Jarak', contentItems: [{ content: state.usaha.business_nearby_distance, prepend: ' Meter' }], isShow: state.usaha.is_business_nearby_exist === true },
        { title: 'Jumlah Pengunjung/Hari', contentItems: [{ content: state.usaha.customer_per_day }], isShow: true },
        { title: 'Hari Kerja/Bulan', contentItems: [{ content: state.usaha.working_days_per_month }], isShow: true },
        { title: 'Persediaan Barang', contentItems: [{ content: state.usaha.inventory }], isShow: true },
        { title: 'Sarana Usaha', contentItems: [{ content: checkExist(state.usaha.is_business_facilities) }], isShow: true },
        { title: 'Jumlah Sarana Usaha', contentItems: [{ content: state.usaha.total_business_facilities }], isShow: state.usaha.is_business_facilities === true },
        { title: 'Kepemilikan Sarana', contentItems: [{ content: state.usaha.facilities_ownership }], isShow: state.usaha.is_business_facilities === true },
        { title: 'Karyawan', contentItems: [{ content: checkExist(state.usaha.is_employee_exist) }], isShow: true },
        { title: 'Jumlah Karyawan', contentItems: [{ content: state.usaha.num_employees }], isShow: state.usaha.is_employee_exist === true },
        { title: 'Catatan', contentItems: [{ content: state.usaha.notes }], isShow: true },
      ]
    },

    renderInformasiPihakKetiga: (state) => {
      const checkExist = (isExist: any) => {
        return isExist === null || isExist === undefined ? '-' : isExist === true ? 'Ya' : 'Tidak'
      }
      if (!state.informasi_pihak_ketiga)
        return []
      return [
        { title: 'Apakah Usaha Konsumen Benar Ada', contentItems: [{ content: checkExist(state.informasi_pihak_ketiga.business_exist) }], isShow: true },
        { title: 'Apakah Benar Dimiliki Oleh Konsumen', contentItems: [{ content: checkExist(state.informasi_pihak_ketiga.business_ownership) }], isShow: true },
        { title: 'Kepemilikan Tempat Usaha Konsumen Sesuai Aplikasi', contentItems: [{ content: checkExist(state.informasi_pihak_ketiga.is_business_status_apk) }], isShow: true },
        { title: 'Mulai Usaha Konsumen Sesuai Aplikasi', contentItems: [{ content: checkExist(state.informasi_pihak_ketiga.is_business_since_apk) }], isShow: true },
        { title: 'Usaha Sejak', contentItems: [{ content: `${state.informasi_pihak_ketiga.business_since_month}`, type: 'month' }, { content: `${state.informasi_pihak_ketiga.business_since_year} ` }], isShow: state.informasi_pihak_ketiga.is_business_since_apk === false },
        { title: 'Apakah Usaha Yang Dijalankan Konsumen Berganti-ganti', contentItems: [{ content: checkExist(state.informasi_pihak_ketiga.is_business_turnover) }], isShow: true },
        { title: 'Aktifitas Usaha Konsumen', contentItems: [{ content: state.informasi_pihak_ketiga.business_activity }], isShow: true },
        { title: 'Perkembangan', contentItems: [{ content: state.informasi_pihak_ketiga.business_development }], isShow: true },
        { title: 'Verifikasi Dengan', contentItems: [{ content: state.informasi_pihak_ketiga.verify_with }], isShow: true },
        { title: 'Lokasi Verifikasi', contentItems: [{ content: state.informasi_pihak_ketiga.verify_location }], isShow: true },
        { title: 'Catatan', contentItems: [{ content: state.informasi_pihak_ketiga.notes }], isShow: true },
      ]
    },

  },
  actions: {

    async getAkkJobAction(prospect_id: string, type: string, step: string) {
      const { data, error }: any = await useSallyFetchCms(`/v1/kmob/submission/akk/${prospect_id}/detail?section=PEKERJAAN&step=${step}`, { key: `${prospect_id}` })
      if (type === 'KARYAWAN') {
        if (error?.value?.statusCode)
          return error?.value?.statusCode
        if (data?.value)
          this.job = data?.value?.data
      }
      if (type === 'WIRASWASTA') {
        if (error?.value?.statusCode)
          return error?.value?.statusCode
        if (data?.value)
          this.job_wiraswasta = data?.value?.data
      }
    },
    async getAkkJobTitleAction(prospect_id: string, step: string) {
      const { data, error }: any = await useSallyFetchCms(`/v1/kmob/submission/akk/${prospect_id}/detail?section=PEKERJAAN&step=${step}`, { key: `${prospect_id}` })
      if (error?.value?.statusCode)
        return error?.value?.statusCode
      if (data?.value)
        this.job_title = data?.value?.data
    },
    async getAkkJobUsahaAction(prospect_id: string, step: string) {
      const { data, error }: any = await useSallyFetchCms(`/v1/kmob/submission/akk/${prospect_id}/detail?section=PEKERJAAN&step=${step}`, { key: `${prospect_id}` })
      if (error?.value?.statusCode)
        return error?.value?.statusCode
      if (data?.value)
        this.usaha = data?.value?.data
    },
    async getAkkInfromasiPihakKetigaAction(prospect_id: string, step: string) {
      const { data, error }: any = await useSallyFetchCms(`/v1/kmob/submission/akk/${prospect_id}/detail?section=PEKERJAAN&step=${step}`, { key: `${prospect_id}` })
      if (error?.value?.statusCode)
        return error?.value?.statusCode
      if (data?.value)
        this.informasi_pihak_ketiga = data?.value?.data
    },
  },

})

if (import.meta.hot)
  import.meta.hot.accept(acceptHMRUpdate(useKmobAkkJob, import.meta.hot))
