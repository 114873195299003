<script setup>
import { useKmobAkkDetail } from '~/stores/kmob/akk/detail'
import { useKmobAkkJob } from '~/stores/kmob/akk/job'

const store = useKmobAkkJob()
const detailStore = useKmobAkkDetail()
const { application } = storeToRefs(detailStore)

await store.getAkkJobAction(application.value.prospect_id, application.value.profession_type, 'PEKERJAAN')
</script>

<template>
  <div>
    <KmobCard title="Data Pekerjaan" :border-bottom="false" class="mx-8">
      <KmobRenderDynamicContent v-if="application.profession_type === 'KARYAWAN'" :data="store.renderJobKaryawan" />
      <KmobRenderDynamicContent v-if="application.profession_type === 'WIRASWASTA'" :data="store.renderJobWiraswasta" />
    </KmobCard>
  </div>
</template>
