<script setup>
import { useKmobAkkDetail } from '~/stores/kmob/akk/detail'
import { useKmobAkkJob } from '~/stores/kmob/akk/job'

const store = useKmobAkkJob()
const detailStore = useKmobAkkDetail()
const { application } = storeToRefs(detailStore)
await store.getAkkJobTitleAction(application.value.prospect_id, 'JABATAN')
</script>

<template>
  <div>
    <KmobCard title="Data Jabatan" :border-bottom="false" class="mx-8">
      <KmobRenderDynamicContent :data="store.renderJobPosition" />
    </KmobCard>
  </div>
</template>
