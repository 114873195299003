<script setup>
import { useKmobAkkDetail } from '~/stores/kmob/akk/detail'

const store = useKmobAkkDetail()
const { application } = storeToRefs(store)
const breadcrumbStore = useBreadCrumb()
breadcrumbStore.setBreadCrumb([{ label: 'AKK', path: '/kmob/akk', active: false },
  { label: 'Detail AKK', path: `/kmob/akk/detail/${application.value.prospect_id}`, active: false },
  { label: 'Pekerjaan', path: '', active: true },
])

const tabRender = computed(() => {
  return application.value?.profession_type
  === 'KARYAWAN'
    ? ['Pekerjaan', 'Jabatan']
    : ['Pekerjaan', 'Usaha', 'Informasi Pihak Ketiga']
})
const activeTab = ref('Pekerjaan')
watchEffect(() => {
  store.activeTab = activeTab.value
})
</script>

<template>
  <div>
    <div class="inline-block mx-8">
      <KmobTabs :tabs="tabRender" :active-tab="activeTab" width="200px" @change="activeTab = $event" />
    </div>
    <KmobAkkDetailTabsJobs v-if="activeTab === 'Pekerjaan'" />
    <KmobAkkDetailTabsJobsPosition v-if="activeTab === 'Jabatan'" />
    <KmobAkkDetailTabsUsaha v-else-if="activeTab === 'Usaha'" />
    <LazyKmobAkkDetailTabsInformasiPihakKetiga v-if="activeTab === 'Informasi Pihak Ketiga'" />
  </div>
  <KmobAkkDetailDataFooter />
</template>
